<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">评论管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="titleNameNumber">
          <div class="l">{{ projectName }}-{{ courseName }}</div>
          <div class="r">
            参与人
            <span style="color: #5c6be8;">{{ userNum }}</span>
            次
            <span style="padding:0 5px;"></span> 被举报人
            <span style="color: #F56C6C;">{{ reportNum }}</span>
            次
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <Reply
              v-if="listData.length"
              :listData="listData"
              :projectId="projectId"
              :projectCourseId="projectCourseId"
              @testShowName="getListData"
            />
            <Empty v-if="!listData.length" slot="empty" />
          </div>
        </div>
        <div class="pageNum">
          <el-pagination
            :total="fenYe.total"
            :page-size="fenYe.size"
            :current-page="fenYe.currentPage"
            background
            :page-sizes="[10, 20, 30, 40, 50]"
            layout="total, sizes,prev, pager, next,jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import Reply from "@/views/workOrder/reply.vue";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/commentManagementInfo",
  components: {
    Empty,
    Reply,
  },
  data() {
    return {
      projectId: "", //班级id
      projectCourseId: "", //班级课程ID
      projectName: "", // 班级名称
      courseName: "", // 课程名称
      reportNum: "", // 举报次数
      userNum: "", // 用户数量
      listData: [],
      // 分页
      fenYe: {
        currentPage: 1, // 当前页数
        size: 10,
        total: null, // 共多少页
      },
    };
  },
  watch: {},
  created() {
    this.projectId = JSON.parse(this.$route.query.row).projectId;
    this.projectCourseId = JSON.parse(this.$route.query.row).projectCourseId;
    this.projectName = JSON.parse(this.$route.query.row).projectName;
    this.courseName = JSON.parse(this.$route.query.row).courseName;
    // this.reportNum = JSON.parse(this.$route.query.row).reportNum;
    // this.userNum = JSON.parse(this.$route.query.row).userNum;
    // 初始化获取列表数据
    this.getListData(this.fenYe.currentPage, this.fenYe.size);
    this.getJbAanCy();
    
  },
  computed: {},
  mounted() {},
  methods: {
    // 每页多少条
    handleSizeChange(val) {
      this.fenYe.size = val;
      this.fenYe.currentPage = 1;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 当前页数
    handleCurrentChange(val) {
      this.fenYe.currentPage = val;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 初始化获取评论列表
    getListData(page = 1, pagesize = this.fenYe.size) {
      const params = {
        projectId: this.projectId,
        projectCourseId: this.projectCourseId,
        pageNum: page,
        pageSize: pagesize,
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      this.$post("/biz/course/discuss/getInfo", params)
        .then((res) => {
          for (let i = 0; i < res.data.list.length; i++) {
            res.data.list[i].show = false;
            res.data.list[i].currentPage = 1;
          }
          this.listData = res.data.list;
          this.fenYe.total = res.data.total;
        })
        .catch(() => {
          return;
        });
    },
    // 获取该班级的该课程的数据 - 用来显示举报人数和参与人数
    getJbAanCy(){
      const params = {
        pageNum: 1,
        pageSize: 1,
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      this.$post("/biz/course/discuss/pageList", params)
        .then((res) => {
          this.reportNum = res.data.list[0].reportNum;
          this.userNum = res.data.list[0].userNum;
        })
        .catch(() => {
          return;
        });
    }
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #5c6be8;
}
.titleNameNumber {
  height: 40px;
  line-height: 40px;
  padding: 0 1rem;
  border-bottom: 1px solid #eee;
  .l {
    float: left;
  }
  .r {
    float: right;
    span {     
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>